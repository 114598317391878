import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

import Headline from '../elements/headline'
import Paragraph from '../elements/paragraph'

const PodloveAlternateFeeds = () => (
  <Layout
    hero={[
      'Podlove Alternate Feeds',
      'Podlove Alternate Feeds is a method for describing related podcast feeds from within another feed, therefore creating a group of related feeds that podcast clients can automatically detect and present to the user.',
    ]}
  >
    <Seo title="Podlove Deep Linking" />
    <div
      className="
        flex
        flex-wrap
        items-stretch
        ml-auto
        mr-auto
        mt-16
        pb-20
        max-w-full
        md:max-w-2xl
        px-4
      "
    >
      <Headline level={2}>Motivation and scope</Headline>
      <Paragraph>Some podcasts create multiple feeds relating to the same or similar content. Depending on which podcast feed a user has subscribed to, different types of media containers or codecs might be used. Alternative feeds might also present either audio or audiovisual representation of an original recording or present different transport mechanisms like BitTorrent.</Paragraph>
      <Paragraph>Podlove Alternate Feeds is a very simple definition to make discovery of alternative feeds easy and automatic as users often never look up background information on feeds on websites and therefore might not know of their options.</Paragraph>
      <Paragraph>Using the method described below podcast clients can decide to present this information to the user when subscribing to a feed initially (or whenever they feel appropriate) to provide additional options.</Paragraph>
      <Headline level={2}>Setting a title for feeds</Headline>
      <Paragraph>Each podcast feed SHOULD include <code>&lt;atom:link rel=&quot;self&quot; ...&gt;</code> element in the feeds global section to link back to its original feed. This is a common procedure. But the optional <code>title</code> attribute is usually left out. We recommend setting this title to describe the nature of the feed in the podcast feed’s original language.</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          {`<?xml version="1.0" encoding="utf-8"?>
<feed xmlns="http://www.w3.org/2005/Atom">
<title>My Cool Podcast</title>
<link rel="self"
  href="http://mycoolpodcast.com/feed/mp3"
  type="application/atom+xml"
  title="MP3 audio"/>`}
        </code>
      </pre>
      <Paragraph>By providing a title for the current feed itself, podcast clients can display this title alongside the titles of alternate feeds (see below)</Paragraph>
      <Headline level={2}>Including alternate feeds</Headline>
      <Paragraph>Extending the above section, the podcast feed CAN now list all other available podcast feeds that serve the same content.</Paragraph>
      <Paragraph>The following example states feeds delivering different media containers for the same audio content:</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          {`<link rel="alternate"
  href="http://mycoolpodcast.com/feed/mp3-low"
  type="application/atom+xml"
  title="MP3 audio low-bandwidth"/>
<link rel="alternate"
  href="http://mycoolpodcast.com/feed/mp4"
  type="application/atom+xml"
  title="MP4 audio"/>
<link rel="alternate"
  href="http://mycoolpodcast.com/feed/ogg"
  type="application/atom+xml"
  title="Ogg Vorbis audio"/>`}
        </code>
      </pre>
      <Paragraph>This example lists video variants as well as BitTorrent feeds for the same content:</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          {`<link rel="alternate"
  href="http://mycoolpodcast.com/feed/video"
  type="application/atom+xml"
  title="MP4 video"/>
<link rel="alternate"
  href="http://mycoolpodcast.com/feed/video-bt"
  type="application/atom+xml"
  title="MP4 video (via BitTorrent)"/>`}
        </code>
      </pre>
      <Paragraph>Please note the only real difference in these elements is the feed URL and the title. Make sure the title is descriptive enough for the user to understand the difference at first glance. Maintain a common style among all titles to achieve coherency for the user.</Paragraph>
      <Paragraph>The title is not meant to be machine readable or automatically selected by software. A podcast client SHOULD provide a list of options and make the user choose from the list.</Paragraph>
      <Headline level={2}>Linking in RSS</Headline>
      <Paragraph>The above examples describe Atom feeds only. In RSS, you can use the very same method but MUST make sure you declare the Atom Syndication namespace properly and use the namespace prefix with the link element.</Paragraph>
      <pre
        className="
          rounded-lg
          bg-blue-700
          text-blue-200
          mb-10
          px-4
          py-6
          w-full
          overflow-x-scroll
        "
      >
        <code>
          {`<?xml version="1.0" encoding="utf-8"?>
<rss version=2.0" xmlns:atom="http://www.w3.org/2005/Atom">
<title>My Cool Podcast</title>
<atom:link rel="self"
  href="http://mycoolpodcast.com/feed/mp3"
  type="application/atom+xml"
  title="MP3 audio"/>`}
        </code>
      </pre>
    </div>
  </Layout>
)

export default PodloveAlternateFeeds
